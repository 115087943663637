import React from "react";
// import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import "./index.css";
import router from "./router";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//    <RouterProvider router={router} />
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root") as HTMLElement;
const renderHandler = rootElement.hasChildNodes() ? hydrate : render;
renderHandler(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
