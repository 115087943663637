import { useLoaderData } from "react-router-dom";
import companyLogo from "./images/conpany_logo.png";
import logoImg from "@/assets/images/logo.webp";
import styles from "./index.module.scss";

export const loader = async ({ params }: { params: Record<string, any> }) => {
  const { fileName } = params;
  const content = (
    await import(`logic/src/locale/character/agreement/wb/${fileName}`)
  ).default;
  return { content };
};

const Agreement = () => {
  const { content } = useLoaderData() as Record<string, any>;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <header>
          <img src={logoImg} className={styles.logo} />
        </header>
        <section>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </section>
        <footer>
          <img src={companyLogo} className={styles["left-logo"]} />
          <img src={logoImg} className={styles.logo} />
        </footer>
      </div>
    </div>
  );
};
export const Component = Agreement;
