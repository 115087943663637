import { createBrowserRouter, Navigate } from "react-router-dom";
import { loader as agreementLoader } from "@/pages/Agreement";
import Root from "./Root";
import ErrorPage from "@/pages/Error";

const router = createBrowserRouter(
  [
    {
      path: "/character-wb/test",
      element: <div>Hello world!</div>,
    },
    {
      path: "/",
      element: <Navigate to="/character-wb/home" replace />,
    },
    {
      path: "/character-wb",
      element: <Navigate to="/character-wb/home" replace />,
    },
    {
      path: "/character-wb/home",
      lazy: () => import("./pages/Home"),
    },
    {
      path: "/character-wb/docs/:fileName",
      loader: agreementLoader,
      lazy: () => import("./pages/Agreement"),
    },
    // {
    //   path: "/",
    //   element: <Root />,
    //   errorElement: <ErrorPage />,
    //   children: [
    //     {
    //       path: "/character-wb",
    //       element: <Navigate to="/home" replace />,
    //     },
    //     {
    //       path: "/character-wb/home",
    //       lazy: () => import("./pages/Home"),
    //     },
    //     {
    //       path: "/character-wb/docs/:fileName",
    //       loader: agreementLoader,
    //       lazy: () => import("./pages/Agreement"),
    //     },
    //   ],
    // },
  ],
  // {
  //   basename: "/character-wb",
  // },
);

export default router;
